video {
    border-radius: 8px;
}

.posrel {
    position: relative;
}

.posabs {
    position: absolute;
    bottom: 20px;
    left: 29px;
    color: white;
}