 li.nav-item {
     background: #80808070;
 }

 .nav-link {
     color: white !important;
 }

 .nav-link:focus,
 .nav-link:hover {
     color: white;
     border: none;
 }

 .nav-tabs .nav-item.show .nav-link,
 .nav-tabs .nav-link.active {
     color: white;
     background-color: #80808070;
     border: transparent;
     border-bottom: 3px solid white;
     border-radius: 0px;
 }