body {
  background-color: #212529 !important;
  /* background-image: url(../public/img/bg.jpg); */
  /* object-fit: cover;
  background-repeat: no-repeat; */
  /* padding: 8px; */
}

a {
  text-decoration: none;
}

.container {
  margin-bottom: 8px;
}

.borbot {
  border-top: 1px solid #80808096;
}

a.btn {
  background: #80808070;
  border: 1px solid #80808070;
}

a.btn:hover,
a.btn:active {
  background: grey;
  border: 1px solid grey;
}

.grey-btn {
  background: #80808070;
}

.grey-btn:hover {
  background: grey;
  border: 1px solid grey;
}

nav.bg-white.navbar.navbar-expand-lg.navbar-light {
  margin-top: 8px;
}

::placeholder {
  color: grey;
}