.profile-round {
    width: 70px;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: #212529;
    background-clip: padding-box;
    border: var(--bs-border-width) solid #495057;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media only screen and (max-width: 1366px) {
    .profile-round {
        width: 50px;
    }
}

@media only screen and (max-width: 990px) {
    .profile-round {
        width: 100px;
    }
}